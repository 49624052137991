export const clientTypes = {
  physical: 'Физическое лицо',
  legal: 'Юридическое лицо'
}

export const clientStatuses = {
  declined: 'Отклонено',
  accepted: 'Подтверждён',
  pending: 'Необходимо подтверждение'
}

export const orderStatuses = {
  searching: 'Поиск',
  waiting: 'Ожидание',
  on_way: 'Выезд',
  loading: 'Погрузка',
  transit: 'Перевозка',
  unloading: 'Разгрузка',
  completed: 'Выполнено',
  driver_not_found: 'Водитель не найден',
  canceled_by_manager: 'Отмена менеджером',
}

export const orderPaymentStatuses = {
  paid: 'Оплачен',
  not_paid: 'Не оплачен',
  invoiced: 'Выставлен счёт'
}

export const loadingType = {
  any: 'Без предпочтений',
  top: 'Верхняя',
  side: 'Боковая',
}

export const bodyType = {
  none: 'Без предпочтений',
  fullmetal: 'Цельнометаллический',
  tent: 'Тентованный',
  bort: 'Бортовой',
  refrigerator: 'Рефрижератор',
  isoterm: 'Изотермический',
}

export const paymentType = {
  cash: 'Наличные',
  card: 'Картой',
  manual: 'Безналичный'
}

export const yesNoAnswer = [
  {label: 'Да', value: 'true'},
  {label: 'Нет', value: 'false'},
]

export const bodyTypeArray = [
  {label: 'Неизвестно', value: 'none'},
  {label: 'Бортовой', value: 'bort'},
  {label: 'Тентованный', value: 'tent'},
  {label: 'Рефрижератор', value: 'refrigerator'},
  {label: 'Изотермический', value: 'isoterm'},
  {label: 'Цельнометаллический', value: 'fullmetal'},
]

export const driverStatusArray = [
  {label: 'Оффлайн', value: 'offline'},
  {label: 'Онлайн', value: 'online'},
  {label: 'На заказе', value: 'working'},
]

export const userTypeArray = [
  {label: 'Физическое лицо', value: 'physical'},
  {label: 'Юридическое лицо', value: 'legal'},
]

export const statusArray = [
  {name: 'Отклонен', id: 'declined'},
  {name: 'Подтверждён', id: 'accepted'},
  {name: 'Необходимо подтверждение', id: 'pending'},
]

export const orderStatusArray = Object.entries(orderStatuses).map(k => ({
  label: k[1],
  value: k[0]
}))

export const orderPaymentStatusArray = Object.entries(orderPaymentStatuses).map(k => ({
  label: k[1],
  value: k[0]
}))

export const orderColors = [
  '#02C58B',
  '#E8CB9B',
  '#5293E0',
  '#E34B79',
  '#FFCB06',
  '#A281FF',
  '#BDBFC4',
  '#FD9F11',
  '#DCD0FF',
  '#FFE890',
]

export const userRole = {
  admin: 'admin',
  manager: 'manager',
}

export const lengths = [2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 13.6]
export const widths = [1.5, 1.7, 1.9, 2, 2.1, 2.3, 2.4, 2.45, 2.5]
export const heights = [1.5, 1.8, 2, 2.1, 2.3, 2.4, 2.5, 2.6, 3]

export const tariffMocks = [
  {
    name: 'ДО 1 т.',
    maxVolume: 21,
    image: '1.png'
  },
  {
    name: '1-2,5 т.',
    maxVolume: 31,
    image: '2.png'
  },
  {
    name: '2,5-5 т.',
    maxVolume: 47,
    image: '3.png'
  },
  {
    name: '5-10 т.',
    maxVolume: 58,
    image: '4.png'
  },
  {
    name: '10-20 т.',
    maxVolume: 100,
    image: '5.png'
  },
]
